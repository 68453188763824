import { storeToRefs } from 'pinia';
import { unref } from 'vue';
import type { NavigationGuard } from 'vue-router';

import { useAuthStore } from './store';
import type { UserRole } from './types';

export const authNavigationGuard: NavigationGuard = async () => {
  const authStore = useAuthStore();
  const { token } = storeToRefs(authStore);

  if (!unref(token)) {
    return { name: 'signIn' };
  }

  const isTokenValid = await authStore.getUser();

  if (!isTokenValid) {
    return { name: 'signIn' };
  }
};

export const loggedNavigationGuard: NavigationGuard = async () => {
  const { getUser } = useAuthStore();

  const isTokenValid = await getUser();

  if (isTokenValid) {
    return { path: '/' };
  }
};

export const roleNavigationGuard: NavigationGuard = async (to, from) => {
  const authStore = useAuthStore();

  const roles = authStore.user?.roles;
  const requiredRoles = to.meta.roles as UserRole[];

  const routesByRoles: Record<UserRole, string[]> = {
    admin: ['warehouse', 'warehouse-add', 'warehouse-id', 'map', 'map-add', 'map-edit'],
    manager: ['home', 'warehouse', 'map'],
    partner: ['warehouse', 'map'],
  };

  if (authStore.user?.is_superuser) return;
  if (!requiredRoles.length) return { name: from.name as string };

  if (roles?.length) {
    const isAccessAllowed = roles.some((role) => requiredRoles.includes(role));
    const currentRole = roles[0];
    const proposedRouteName = routesByRoles[currentRole].filter((route) => route !== to.name);

    if (isAccessAllowed) {
      return true;
    }

    return { name: proposedRouteName[0] };
  }
};
