import type { Component } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { authNavigationGuard, loggedNavigationGuard, roleNavigationGuard } from 'features/auth';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: (): Component => import('pages/(app)/layout.vue'),
      beforeEnter: authNavigationGuard,
      children: [
        {
          path: '',
          name: 'home',
          meta: { roles: ['manager'] },
          beforeEnter: roleNavigationGuard,
          component: (): Component => import('pages/(app)/organizations/index.vue'),
        },
        {
          path: 'warehouse',
          beforeEnter: roleNavigationGuard,
          children: [
            {
              path: '',
              name: 'warehouse',
              meta: { roles: ['admin', 'manager', 'partner'] },
              component: (): Component => import('pages/(app)/warehouse/index.vue'),
            },
            {
              path: '/warehouse/add',
              name: 'warehouse-add',
              meta: { roles: ['admin'] },
              component: (): Component => import('pages/(app)/warehouse/add/index.vue'),
            },
            {
              path: '/warehouse/:id',
              name: 'warehouse-id',
              meta: { roles: ['admin'] },
              component: (): Component => import('pages/(app)/warehouse/id/index.vue'),
            },
          ],
        },
        {
          path: 'map',
          beforeEnter: roleNavigationGuard,
          children: [
            {
              path: '',
              name: 'map',
              meta: { roles: ['admin', 'manager', 'partner'] },
              component: (): Component => import('pages/(app)/map/index.vue'),
            },
            {
              path: '/map/:buildingId/:sectionId',
              name: 'map-add',
              meta: { roles: ['admin'] },
              component: (): Component => import('pages/(app)/map/add/index.vue'),
            },
            {
              path: '/map/:buildingId/:sectionId/:boxId',
              name: 'map-edit',
              meta: { roles: ['admin'] },
              component: (): Component => import('pages/(app)/map/edit/index.vue'),
            },
            {
              path: '/map/rental/:buildingId/:sectionId/:boxId',
              name: 'map-rental',
              meta: { roles: ['admin'] },
              component: (): Component => import('pages/(app)/map/rental/index.vue'),
            },
          ],
        },
        {
          path: 'users',
          meta: { roles: [] },
          beforeEnter: roleNavigationGuard,
          children: [
            {
              path: '',
              name: 'users',
              component: (): Component => import('pages/(app)/users/index.vue'),
            },
          ],
        },
        {
          path: 'tariffs',
          meta: { roles: ['admin', 'manager', 'partner'] },
          component: (): Component => import('pages/(app)/tariffs/layout.vue'),
          beforeEnter: roleNavigationGuard,
          children: [
            {
              path: '',
              name: 'tariffs',
              component: (): Component => import('pages/(app)/tariffs/index.vue'),
            },
            {
              path: '/tariffs/rental-period',
              name: 'tariffs-rental-period',
              component: (): Component => import('pages/(app)/tariffs/rental-period/index.vue'),
            },
            {
              path: '/tariffs/promotions',
              name: 'tariffs-promotions',
              component: (): Component => import('pages/(app)/tariffs/promotions/index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/',
      component: (): Component => import('pages/(app)/default.vue'),
      beforeEnter: authNavigationGuard,
      children: [
        {
          path: '/:catchAll(.*)',
          name: '404',
          component: (): Component => import('pages/(app)/NotFound.vue'),
        },
      ],
    },
    {
      path: '/auth',
      component: (): Component => import('pages/(auth)/layout.vue'),
      beforeEnter: loggedNavigationGuard,
      children: [
        {
          path: '',
          name: 'signIn',
          component: (): Component => import('pages/(auth)/sign-in/index.vue'),
        },
      ],
    },
  ],
});

export default router;
